import whichPolygon from "which-polygon";
import RegionsEuregio_AT_02 from "@eaws/micro-regions/AT-02_micro-regions.geojson.json";
import RegionsEuregio_AT_03 from "@eaws/micro-regions/AT-03_micro-regions.geojson.json";
import RegionsEuregio_AT_04 from "@eaws/micro-regions/AT-04_micro-regions.geojson.json";
import RegionsEuregio_AT_05 from "@eaws/micro-regions/AT-05_micro-regions.geojson.json";
import RegionsEuregio_AT_06 from "@eaws/micro-regions/AT-06_micro-regions.geojson.json";
import RegionsEuregio_AT_07 from "@eaws/micro-regions/AT-07_micro-regions.geojson.json";
import RegionsEuregio_AT_08 from "@eaws/micro-regions/AT-08_micro-regions.geojson.json";
import RegionsEuregio_DE_BY from "@eaws/micro-regions/DE-BY_micro-regions.geojson.json";
import RegionsEuregio_IT_32_BZ from "@eaws/micro-regions/IT-32-BZ_micro-regions.geojson.json";
import RegionsEuregio_IT_32_TN from "@eaws/micro-regions/IT-32-TN_micro-regions.geojson.json";
import { mergeFeatureCollections } from "./mergeFeatureCollections";
import { FeatureCollection, MultiPolygon } from "geojson";

type Properties = (typeof RegionsEuregio_IT_32_TN)["features"][number]["properties"];

const query = whichPolygon(
  mergeFeatureCollections(
    RegionsEuregio_AT_02 as FeatureCollection<MultiPolygon, Properties>,
    RegionsEuregio_AT_03 as FeatureCollection<MultiPolygon, Properties>,
    RegionsEuregio_AT_04 as FeatureCollection<MultiPolygon, Properties>,
    RegionsEuregio_AT_05 as FeatureCollection<MultiPolygon, Properties>,
    RegionsEuregio_AT_06 as FeatureCollection<MultiPolygon, Properties>,
    RegionsEuregio_AT_07 as FeatureCollection<MultiPolygon, Properties>,
    RegionsEuregio_AT_08 as FeatureCollection<MultiPolygon, Properties>,
    RegionsEuregio_DE_BY as FeatureCollection<MultiPolygon, Properties>,
    RegionsEuregio_IT_32_BZ as FeatureCollection<MultiPolygon, Properties>,
    RegionsEuregio_IT_32_TN as FeatureCollection<MultiPolygon, Properties>,
  ),
);

export function getRegionForLatLng(observation: { latitude?: number; longitude?: number }): string {
  if (observation.latitude && observation.longitude) {
    const region = query([observation.longitude, observation.latitude], false) as Properties;
    return region?.id;
  }
  return undefined;
}

export function augmentRegion<T extends { latitude?: number; longitude?: number; region?: string }>(observation: T): T {
  observation.region = getRegionForLatLng(observation);
  return observation;
}
